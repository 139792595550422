import './style_confirmation.css';
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../Firebase/firebase";
import { collection, deleteDoc, doc, getDocs, Timestamp } from 'firebase/firestore';

function Confirmation() {
    /* 
        Start Backend
    */

    // To Read All Data
    const [myData, setMyData] = useState([]);
    console.log(myData);

    const UsersCollectionRef = collection(db, 'Archive');

    useEffect(()=> {

        const getMyData = async()=> {
            const data = await getDocs(UsersCollectionRef);

            const allData = data.docs.map((e)=> ({...e.data(), id: e.id}));

            // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
            allData.sort((a, b) => {
                return a.TimeStamp.toDate() - b.TimeStamp.toDate();
            });

            setMyData(allData);
        };

        getMyData();

    }, []);



    function formatTimestamp(timestamp) {
        // Ensure timestamp is an instance of Firebase Timestamp
        if (!(timestamp instanceof Timestamp)) {
          throw new Error("Expected a Timestamp object");
        }
      
        // Convert Timestamp to Date object
        const date = timestamp.toDate();
      
        // Format the Date object to a readable string
        return date.toLocaleString(); // Adjust as needed for your locale and format
      };





      // Delete One User
    const deleteUser = async(id)=> {
        // عرض نافذة تأكيد
        const confirmDelete = window.confirm("Are you sure you want to delete This Student?");

        
            if(confirmDelete) {
                const userDoc = doc(db, 'Archive', id);
                await deleteDoc(userDoc);
                window.location.reload();
                console.log('Delete');
            };
       
    };



    // Delete All User
    const deleteAllDocuments = async () => {
        console.log('delete all');
        //const db = getFirestore();
        const itemsCollection = collection(db, "Archive");
      
        // عرض نافذة تأكيد
        const confirmDelete = window.confirm("Are you sure you want to delete all items?");
      
        if (confirmDelete) {
          try {
            const querySnapshot = await getDocs(itemsCollection);
            const deletePromises = querySnapshot.docs.map(docSnapshot =>
              deleteDoc(doc(db, "Archive", docSnapshot.id))
            );
      
            await Promise.all(deletePromises);
            window.location.reload();
            
            console.log("All documents deleted successfully");

          } catch (error) {
            console.error("Error deleting documents: ", error);
          }
        } else {
          console.log("Delete operation canceled");
        }
      };


    /* 
        Start For Language Translation
    */
        const [chooseLang, setChooseLang] = useState('En');

        const [selectedLanguage, setSelectedLanguage] = useState('en');
        const [isOpen, setIsOpen] = useState(false);
    
        const languages = {
            en: 'En',
            fr: 'Fr',
            ar: 'Ar',
          };
    
          const toggleDropdown = () => setIsOpen(!isOpen);
    
          const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
            setSelectedLanguage(lng);
            setIsOpen(false);
          };
        
          const getAvailableLanguages = () => {
            return Object.keys(languages).filter((lang) => lang !== selectedLanguage);
          };
    
        const { t } = useTranslation();
        const { i18n } = useTranslation();
    
        /* 
            End For Language Translation
        */
    
        const navigate = useNavigate();
    
        const handleLogout = async()=> {
            try {
                await signOut(auth);
                navigate('/Login');
                localStorage.removeItem('user');
    
            } catch (error) {
                console.error(error);
                alert(error.message);
            }
        };

    return(
        <>
            <div className="my-confirmation">
                 {/* Start Navbar */}
                <div className='navbar'>
                    <div className='left'>
                        <i className="fa-solid fa-file-circle-check"></i>
                        <h1>{t('Prof Youcef')}</h1>
                    </div>

                    <div className='right'>
                        <div className='top-content'>
                            <h3>
                            {t('Manage all your cofirmation from one place')}
                            
                            </h3>

                            <div className='lang'>

                                        {/* Test Start */}
                                        <button onClick={()=> {
                                                toggleDropdown();
                                                //setIsLangShow(!isLangShow);
                                        }}>
                                                {languages[selectedLanguage]}
                                                <i className="fa-solid fa-sort-down"></i>
                                        </button>

                                            {isOpen && (
                                                <div className='my-lang' style={{height: 'auto', padding: '0.5em', transition: '0.3s'}}>
                                                    {getAvailableLanguages().map((lang) => (

                                                    <div className='character' key={lang} onClick={() => changeLanguage(lang)}>
                                                        {languages[lang]}
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                        {/* Test End */}

                            </div>
                            
                            <Link to='/Admin'>
                                 <button className='LogOut'>{t('Admin Panel')}</button>
                            </Link>

                            <button className='LogOut' onClick={handleLogout}>{t('LogOut')}</button>

                        </div>
                    </div>
                </div>
                {/* End Navbar */}



                <div className='my-booking-form'>
                    <div className="head">

                            <button onClick={()=> {deleteAllDocuments()}} >{t('Delete All')}</button>

                            <h2>
                                <span></span>
                                {t('Booking details for each student')}
                                <span></span>
                            </h2>
                    </div>

                {
                        myData.map((e, index)=> {
                            //console.log(e.findIndex());
                            return(
                                <div className='details-form' key={e.id}>
                                    <h1>{`${index + 1}.`}</h1>

                                    <div className="form-container">
                                        <div className="form-row">
                                            <label className="form-label">{t('First Name:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.firstName}`} />
                                            <label className="form-label">{t('Phone Number:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.phoneNumber}`} />
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('First Class:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.firstClass}`} />
                                            <label className="form-label">{t('Second Class:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.secoundClass}`} />
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('Date And Time Of Booking On Web:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${formatTimestamp(e.TimeStamp)}`} />
                                            <label className="form-label">{t('Specialization')}:</label>
                                            <input type="text" className="form-input" defaultValue={`${e.specialization}`} />
                                        </div>
                                    </div>

                                    <div className="form-buttons">
                                        
                                        <button onClick={()=> {

                                            // deleteUser(e.id) ? console.log('i will work after delete') : console.log('i will Not work')
                                            deleteUser(e.id);
                                            //handelMinusOne(e.firstClass, e.secoundClass);

                                        }} className="form-button cancel">{t('Delete')}</button>
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    ); 
};


export default Confirmation;