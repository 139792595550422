import { useRef, useState } from 'react';
import './style_home.css';
import hero_img from '../images/Hero.png';
import MyForm from '../MyForm/MyForm';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import logo_img from '../images/Favicon-Logo.png';

function Home() {
    const [isLangShow, setIsLangShow] = useState(false);
    //console.log(isLangShow);

    const formRef = useRef(null);

    const scrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    
    // For Language Translation
    const [chooseLang, setChooseLang] = useState('En');

    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [isOpen, setIsOpen] = useState(false);

    const languages = {
        en: 'En',
        fr: 'Fr',
        ar: 'Ar',
      };

      const toggleDropdown = () => setIsOpen(!isOpen);

      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        setIsOpen(false);
      };
    
      const getAvailableLanguages = () => {
        return Object.keys(languages).filter((lang) => lang !== selectedLanguage);
      };

    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return(
        <>
            <div className='my-home'>
                {/* Start Navbar */}
                <div className='navbar'>
                    <div className='left'>
                        {/* <i className="fa-solid fa-file-circle-check"></i> */}

                        <img src={logo_img} alt="logo" />
                        
                        <h1>{t('Prof Youcef')}</h1>
                    </div>

                    <div className='right'>
                        <div className='top-content'>
                            <h3>
                            "{t("Begin your path to success! Get 8 exclusive lessons each month for only 6000 DZD.")}
                             <br />
                             <span>{t('Act now')}</span>"
                            </h3>

                            <div className='lang'>
                            

                                {/* Test Start */}
                                <button onClick={()=> {
                                        toggleDropdown();
                                        //setIsLangShow(!isLangShow);
                                }}>
                                        {languages[selectedLanguage]}
                                        <i className="fa-solid fa-sort-down"></i>
                                </button>

                                    {isOpen && (
                                        <div className='my-lang' style={{height: 'auto', padding: '0.5em', transition: '0.3s'}}>
                                            {getAvailableLanguages().map((lang) => (

                                            <div className='character' key={lang} onClick={() => changeLanguage(lang)}>
                                                {languages[lang]}
                                            </div>
                                        ))}
                                        </div>
                                    )}
                                {/* Test End */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Navbar */}

                {/* Start Hero Section */}
                <div className='my-hero'>
                    <div className='hero'>
                        <div className='left'>
                            <h1>
                                {t('A Specialized Platform For')}<br />
                                <span>{t('Online Booking.')}</span>
                                <br />
                                6000
                                <span> {t('DZD / Month')} </span>
                                <br />
                                {t('For 8 Classes')}
                                
                                
                            </h1>
                            <button onClick={scrollToForm}>{t('Book Now')}</button>
                        </div>
                        <div className='right'>
                            <img src={hero_img} />
                        </div>
                    </div>
                </div>
                {/* End Hero Section */}

                {/* Start My Form */}
                <MyForm ref={formRef}/>
                {/* End My Form */}

                <Footer />
                
            </div>
        </>
    );
};


export default Home;