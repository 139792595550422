import './style.admin.css';
import manage_img from '../images/manage.png';
import { useEffect, useRef, useState } from 'react';
import { addDoc, collection, deleteDoc, doc, getDocs, increment, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Firebase/firebase';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';

function Admin() {
    const [isLangShow, setIsLangShow] = useState(false);
    //console.log(isLangShow);

    /* 
        Start Backend
    */

    // To Read All Data
    const [myData, setMyData] = useState([]);
    console.log(myData);

    const UsersCollectionRef = collection(db, 'items');

    useEffect(()=> {

        const getMyData = async()=> {
            const data = await getDocs(UsersCollectionRef);

            const allData = data.docs.map((e)=> ({...e.data(), id: e.id}));

            // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
            allData.sort((a, b) => {

                return a.TimeStamp.toDate() - b.TimeStamp.toDate();
            });


            // تحقق من أن TimeStamp هو كائن Timestamp
          //   if (allData.length > 0 && allData[0].TimeStamp instanceof firebase.firestore.Timestamp) {
          //     // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
          //     allData.sort((a, b) => {
          //         return a.TimeStamp.toDate() - b.TimeStamp.toDate();
          //     });
          // } else {
          //     console.error('TimeStamp is not a valid Timestamp instance.');
          // }



            setMyData(allData);
        };

        getMyData();

    }, []);



    // useEffect(() => {
    //       const getMyData = async () => {
    //           try {
    //               const data = await getDocs(UsersCollectionRef);

    //               // تحويل الوثائق إلى مصفوفة من الكائنات
    //               const allData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    //               // تحقق من أن TimeStamp هو كائن Timestamp
    //               if (allData.length > 0 && allData[0].TimeStamp instanceof firebase.firestore.Timestamp) {
    //                   // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
    //                   allData.sort((a, b) => {
    //                       return a.TimeStamp.toDate() - b.TimeStamp.toDate();
    //                   });
    //               } else {
    //                   console.error('TimeStamp is not a valid Timestamp instance.');
    //               }

    //               setMyData(allData);
    //           } catch (error) {
    //               console.error('Error fetching data:', error);
    //           }
    //       };

    //       getMyData();
    // }, []);


    function formatTimestamp(timestamp) {
        // Ensure timestamp is an instance of Firebase Timestamp
        if (!(timestamp instanceof Timestamp)) {
          throw new Error("Expected a Timestamp object");
        }
      
        // Convert Timestamp to Date object
        const date = timestamp.toDate();
      
        // Format the Date object to a readable string
        return date.toLocaleString(); // Adjust as needed for your locale and format
      }


    // First Class
    const class_1_inFirstClass = 'Tuesday - From 2 Pm To 4 Pm';
    const class_2_inFirstClass = 'Tuesday- From 4 Pm To 6 Pm';
    const class_3_inFirstClass = 'Tuesday- From 6 Pm To 8 Pm';
    const class_4_inFirstClass = 'Thursday- From 6 Pm To 8 Pm';
    const class_5_inFirstClass = 'Friday- From 8 Am To 10 Am';
    const class_6_inFirstClass = 'Friday- From 10 Am To 12 Pm';

    // Secound Class
    const class_1_inSecoundClass = 'Friday - From 2 Pm To 4 Pm';
    const class_2_inSecoundClass = 'Friday - From 4 Pm To 6 Pm';
    const class_3_inSecoundClass = 'Saturday - From 8 Am To 10 Am';
    const class_4_inSecoundClass = 'Saturday - From 10 Am To 12 Pm';
    const class_5_inSecoundClass = 'Saturday - From 2 Pm To 4 Pm';
    const class_6_inSecoundClass = 'Saturday - From 4 Pm To 6 Pm';

    const counterRefForAvaliableBooking = doc(db, "counters", "counterForAvaliableBooking"); // المتاح للحجز
    const counterRef = doc(db, "counters", "AmPTnb6yWna8Ln3gyeDS");  // AmPTnb6yWna8Ln3gyeDS ==> id of document (قيد الحجز)
    const counterRefForDoneBooking = doc(db, "counters", "counterForDoneBooking"); // counterForDoneBooking ==> id in firestore (المحجوز)

    // To Update Data On Database ==> increment(1)
    const handelConvermation = async(fi, se)=> {
        console.log('Confirm');

        // =================== To Update Class 1 With All Class 2  ===========================//
        if(fi === class_1_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });

                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });
                  console.log("doneFirstClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne doneFirstClassTow : ", error);
                }
              };
              incrementFirstClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_1_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });

                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });

                  console.log("doneFirstClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne doneSecoundClassTow : ", error);
                }
              };
              incrementFirstClassOneWith_2_ClassTow();

        }

        else if(fi === class_1_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });

                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });

                  console.log("doneFirstClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne doneThirdClassTow : ", error);
                }
              };
              incrementFirstClassOneWith_3_ClassTow();

        }


        else if(fi === class_1_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("doneFirstClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne done_4_ClassTow : ", error);
                }
              };
              incrementFirstClassOneWith_4_ClassTow();

        }

        else if(fi === class_1_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });

                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });

                  console.log("doneFirstClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne done_5_ClassTow : ", error);
                }
              };
              incrementFirstClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_1_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const incrementFirstClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneFirstClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("doneFirstClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneFirstClassOne done_6_ClassTow : ", error);
                }
              };
              incrementFirstClassOneWith_6_ClassTow();

        }




        // =================== To Update Class 2 With All Class 2  ===========================//
        if(fi === class_2_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne doneFirstClassTow : ", error);
                }
              };
              increment_2_ClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_2_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne doneSecoundClassTow : ", error);
                }
              };
              increment_2_ClassOneWith_2_ClassTow();

        }

        else if(fi === class_2_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne doneThirdClassTow : ", error);
                }
              };
              increment_2_ClassOneWith_3_ClassTow();

        }


        else if(fi === class_2_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne done_4_ClassTow : ", error);
                }
              };
              increment_2_ClassOneWith_4_ClassTow();

        }

        else if(fi === class_2_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne done_5_ClassTow : ", error);
                }
              };
              increment_2_ClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_2_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const increment_2_ClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneSecoundClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    secoundClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("doneSecoundClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneSecoundClassOne done_6_ClassTow : ", error);
                }
              };
              increment_2_ClassOneWith_6_ClassTow();

        }





        // =================== To Update Class 3 From First With All Class 2  ===========================//
        if(fi === class_3_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne doneFirstClassTow : ", error);
                }
              };
              increment_3_ClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_3_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne doneSecoundClassTow : ", error);
                }
              };
              increment_3_ClassOneWith_2_ClassTow();

        }

        else if(fi === class_3_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne doneThirdClassTow : ", error);
                }
              };
              increment_3_ClassOneWith_3_ClassTow();

        }


        else if(fi === class_3_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne done_4_ClassTow : ", error);
                }
              };
              increment_3_ClassOneWith_4_ClassTow();

        }

        else if(fi === class_3_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne done_5_ClassTow : ", error);
                }
              };
              increment_3_ClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_3_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const increment_3_ClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    doneThirdClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    thirdClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("doneThirdClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing doneThirdClassOne done_6_ClassTow : ", error);
                }
              };
              increment_3_ClassOneWith_6_ClassTow();

        }





        // =================== To Update Class 4 From First With All Class 2  ===========================//
        if(fi === class_4_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne doneFirstClassTow : ", error);
                }
              };
              increment_4_ClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_4_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne doneSecoundClassTow : ", error);
                }
              };
              increment_4_ClassOneWith_2_ClassTow();

        }

        else if(fi === class_4_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne doneThirdClassTow : ", error);
                }
              };
              increment_4_ClassOneWith_3_ClassTow();

        }


        else if(fi === class_4_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne done_4_ClassTow : ", error);
                }
              };
              increment_4_ClassOneWith_4_ClassTow();

        }

        else if(fi === class_4_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne done_5_ClassTow : ", error);
                }
              };
              increment_4_ClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_4_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const increment_4_ClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_4_ClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fourthClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("done_4_ClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_4_ClassOne done_6_ClassTow : ", error);
                }
              };
              increment_4_ClassOneWith_6_ClassTow();

        }



        // =================== To Update Class 5 From First With All Class 2  ===========================//
        if(fi === class_5_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne doneFirstClassTow : ", error);
                }
              };
              increment_5_ClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_5_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne doneSecoundClassTow : ", error);
                }
              };
              increment_5_ClassOneWith_2_ClassTow();

        }

        else if(fi === class_5_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne doneThirdClassTow : ", error);
                }
              };
              increment_5_ClassOneWith_3_ClassTow();

        }


        else if(fi === class_5_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne done_4_ClassTow : ", error);
                }
              };
              increment_5_ClassOneWith_4_ClassTow();

        }

        else if(fi === class_5_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne done_5_ClassTow : ", error);
                }
              };
              increment_5_ClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_5_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const increment_5_ClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_5_ClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    fifthClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("done_5_ClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_5_ClassOne done_6_ClassTow : ", error);
                }
              };
              increment_5_ClassOneWith_6_ClassTow();

        }



        // =================== To Update Class 6 From First With All Class 2  ===========================//
        if(fi === class_6_inFirstClass && se === class_1_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWithFirstClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    doneFirstClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    firstClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne doneFirstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne doneFirstClassTow : ", error);
                }
              };
              increment_6_ClassOneWithFirstClassTow();



        } 
        
        else if(fi === class_6_inFirstClass && se === class_2_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWith_2_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    doneSecoundClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    secoundClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne doneSecoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne doneSecoundClassTow : ", error);
                }
              };
              increment_6_ClassOneWith_2_ClassTow();

        }

        else if(fi === class_6_inFirstClass && se === class_3_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWith_3_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    doneThirdClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    thirdClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne doneThirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne doneThirdClassTow : ", error);
                }
              };
              increment_6_ClassOneWith_3_ClassTow();

        }


        else if(fi === class_6_inFirstClass && se === class_4_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWith_4_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    done_4_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    fourthClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne done_4_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne done_4_ClassTow : ", error);
                }
              };
              increment_6_ClassOneWith_4_ClassTow();

        }

        else if(fi === class_6_inFirstClass && se === class_5_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWith_5_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    done_5_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    fifthClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne done_5_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne done_5_ClassTow : ", error);
                }
              };
              increment_6_ClassOneWith_5_ClassTow();

        }
        
        else if(fi === class_6_inFirstClass && se === class_6_inSecoundClass) {
            console.log('i will upload');

            const increment_6_ClassOneWith_6_ClassTow = async () => {
                try {
                  await updateDoc(counterRefForDoneBooking, {
                    // This In Firestore
                    done_6_ClassOne: increment(1),
                    done_6_ClassTow: increment(1),
                  });


                  await updateDoc(counterRef, {
                    // This In Firestore
                    sixthClassOne: increment(-1),
                    sixthClassTow: increment(-1),
                  });


                  console.log("done_6_ClassOne done_6_ClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing done_6_ClassOne done_6_ClassTow : ", error);
                }
              };
              increment_6_ClassOneWith_6_ClassTow();

        }
        
        
        
        else {
            console.log('No Math Anything');
        }

    };






    // To Save Confirmation In Archife
    const confirmationForArchiveCollectionRef = collection(db, 'Archive');

    const handelConfirmationForArchive = async(fN, lN, phN, fC, sC, tS, sP)=> {
          console.log('Create On Archive');

          await addDoc(confirmationForArchiveCollectionRef, {
            firstName: fN,
            lastName: lN,
            phoneNumber: phN,
            specialization: sP,
            firstClass: fC,
            secoundClass: sC,
            TimeStamp: tS,
        });
    };








    // To Update Data On Database ==> increment(1)
    const handelMinusOne = async(fi, se)=> {
      console.log('MinusOne');

      // =================== To Update Class 1 With All Class 2  ===========================//
      if(fi === class_1_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_1_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWith_2_ClassTow();

      }

      else if(fi === class_1_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWith_3_ClassTow();

      }


      else if(fi === class_1_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWith_4_ClassTow();

      }

      else if(fi === class_1_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_1_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const incrementFirstClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  firstClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fristAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            incrementFirstClassOneWith_6_ClassTow();

      }




      // =================== To Update Class 2 With All Class 2  ===========================//
      if(fi === class_2_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_2_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWith_2_ClassTow();

      }

      else if(fi === class_2_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWith_3_ClassTow();

      }


      else if(fi === class_2_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });

                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWith_4_ClassTow();

      }

      else if(fi === class_2_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_2_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const increment_2_ClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  secoundClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  secoundAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_2_ClassOneWith_6_ClassTow();

      }





      // =================== To Update Class 3 From First With All Class 2  ===========================//
      if(fi === class_3_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_3_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWith_2_ClassTow();

      }

      else if(fi === class_3_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWith_3_ClassTow();

      }


      else if(fi === class_3_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWith_4_ClassTow();

      }

      else if(fi === class_3_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_3_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const increment_3_ClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  thirdClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  thirdAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_3_ClassOneWith_6_ClassTow();

      }





      // =================== To Update Class 4 From First With All Class 2  ===========================//
      if(fi === class_4_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_4_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWith_2_ClassTow();

      }

      else if(fi === class_4_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWith_3_ClassTow();

      }


      else if(fi === class_4_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWith_4_ClassTow();

      }

      else if(fi === class_4_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_4_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const increment_4_ClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fourthClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fourthAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_4_ClassOneWith_6_ClassTow();

      }



      // =================== To Update Class 5 From First With All Class 2  ===========================//
      if(fi === class_5_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });



                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_5_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWith_2_ClassTow();

      }

      else if(fi === class_5_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWith_3_ClassTow();

      }


      else if(fi === class_5_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWith_4_ClassTow();

      }

      else if(fi === class_5_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_5_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const increment_5_ClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  fifthClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  fifthAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_5_ClassOneWith_6_ClassTow();

      }



      // =================== To Update Class 6 From First With All Class 2  ===========================//
      if(fi === class_6_inFirstClass && se === class_1_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWithFirstClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  firstClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  fristAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWithFirstClassTow();



      } 
      
      else if(fi === class_6_inFirstClass && se === class_2_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWith_2_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  secoundClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  secoundAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWith_2_ClassTow();

      }

      else if(fi === class_6_inFirstClass && se === class_3_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWith_3_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  thirdClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  thirdAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWith_3_ClassTow();

      }


      else if(fi === class_6_inFirstClass && se === class_4_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWith_4_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  fourthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  fourthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWith_4_ClassTow();

      }

      else if(fi === class_6_inFirstClass && se === class_5_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWith_5_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  fifthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  fifthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWith_5_ClassTow();

      }
      
      else if(fi === class_6_inFirstClass && se === class_6_inSecoundClass) {
          console.log('i will upload');

          const increment_6_ClassOneWith_6_ClassTow = async () => {
              try {
                await updateDoc(counterRef, {
                  // This In Firestore
                  sixthClassOne: increment(-1),
                  sixthClassTow: increment(-1),
                });


                await updateDoc(counterRefForAvaliableBooking, {
                  // This In Firestore
                  sixthAvaliableClassOne: increment(1),
                  sixthAvaliableClassTow: increment(1),
                });


                console.log("-1");

              } catch (error) {
                console.error("Error -1 : ", error);
              }
            };
            increment_6_ClassOneWith_6_ClassTow();

      }
      
      
      
      else {
          console.log('No -1 Math Anything');
      }

  };







    // Delete One User
    const deleteUser = async(id)=> {
        // عرض نافذة تأكيد
        //const confirmDelete = window.confirm("Are you sure you want to delete This Student?");

        
            const userDoc = doc(db, 'items', id);
                await deleteDoc(userDoc);
                window.location.reload();
                console.log('Delete');
       
    };


    // Delete One User
    const deleteUserAfterConfirm = async(id)=> {

          const userDoc = doc(db, 'items', id);
              await deleteDoc(userDoc);
              window.location.reload();
              console.log('Delete');
  };

        


    // Delete All User
    const deleteAllDocuments = async () => {
        console.log('delete all');
        //const db = getFirestore();
        const itemsCollection = collection(db, "items");
      
        // عرض نافذة تأكيد
        const confirmDelete = window.confirm("Are you sure you want to delete all items?");
      
        if (confirmDelete) {
          try {
            const querySnapshot = await getDocs(itemsCollection);
            const deletePromises = querySnapshot.docs.map(docSnapshot =>
              deleteDoc(doc(db, "items", docSnapshot.id))
            );
      
            await Promise.all(deletePromises);
            window.location.reload();
            
            console.log("All documents deleted successfully");

          } catch (error) {
            console.error("Error deleting documents: ", error);
          }
        } else {
          console.log("Delete operation canceled");
        }
      };



    // To Reset All First Classes
    const resetFirstClass_1 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fristAvaliableClassOne: 30,
            });

            // المقاعد قيد الحجز
            await updateDoc(counterRef, {
              // This In Firestore
              firstClassOne: 0,
            });

            // المقاعد المحجوزة
            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneFirstClassOne: 0,
            });
          console.log("resetFirstClass_1");

          } catch (error) {
            console.error("Error resetFirstClass_1 : ", error);
          }
      }
      
    };

    const resetFirstClass_2 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              secoundAvaliableClassOne: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              secoundClassOne: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneSecoundClassOne: 0,
            });
          console.log("resetFirstClass_2");

          } catch (error) {
            console.error("Error resetFirstClass_2 : ", error);
          }
      }
      
    };

    const resetFirstClass_3 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {

            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              thirdAvaliableClassOne: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              thirdClassOne: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneThirdClassOne: 0,
            });
          console.log("resetFirstClass_3");

          } catch (error) {
            console.error("Error resetFirstClass_3 : ", error);
          }
      }
      
    };

    const resetFirstClass_4 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fourthAvaliableClassOne: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              fourthClassOne: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_4_ClassOne: 0,
            });
          console.log("resetFirstClass_4");

          } catch (error) {
            console.error("Error resetFirstClass_4 : ", error);
          }
      }
      
    };

    const resetFirstClass_5 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fifthAvaliableClassOne: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              fifthClassOne: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_5_ClassOne: 0,
            });
          console.log("resetFirstClass_5");

          } catch (error) {
            console.error("Error resetFirstClass_5 : ", error);
          }
      }
      
    };

    const resetFirstClass_6 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              sixthAvaliableClassOne: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              sixthClassOne: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_6_ClassOne: 0,
            });
          console.log("resetFirstClass_6");

          } catch (error) {
            console.error("Error resetFirstClass_6 : ", error);
          }
      }
      
    };







    // To Reset All Secound Classes
    const resetSecoundClass_1 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fristAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              firstClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneFirstClassTow: 0,
            });
          console.log("resetSecoundClass_1");

          } catch (error) {
            console.error("Error resetSecoundClass_1 : ", error);
          }
      }
      
    };

    const resetSecoundClass_2 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              secoundAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              secoundClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneSecoundClassTow: 0,
            });
          console.log("resetSecoundClass_2");

          } catch (error) {
            console.error("Error resetSecoundClass_2 : ", error);
          }
      }
      
    };

    const resetSecoundClass_3 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              thirdAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              thirdClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              doneThirdClassTow: 0,
            });
          console.log("resetSecoundClass_3");

          } catch (error) {
            console.error("Error resetSecoundClass_3 : ", error);
          }
      }
      
    };

    const resetSecoundClass_4 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fourthAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              fourthClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_4_ClassTow: 0,
            });
          console.log("resetSecoundClass_4");

          } catch (error) {
            console.error("Error resetSecoundClass_4 : ", error);
          }
      }
      
    };

    const resetSecoundClass_5 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              fifthAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              fifthClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_5_ClassTow: 0,
            });
          console.log("resetSecoundClass_5");

          } catch (error) {
            console.error("Error resetSecoundClass_5 : ", error);
          }
      }
      
    };

    const resetSecoundClass_6 = async()=> {

      const confirmreset = window.confirm("Are you sure you want to reset this class?");

      if(confirmreset) {
          try {
            // المقاعد المتوفرة
            await updateDoc(counterRefForAvaliableBooking, {
              // This In Firestore
              sixthAvaliableClassTow: 30,
            });

            await updateDoc(counterRef, {
              // This In Firestore
              sixthClassTow: 0,
            });

            await updateDoc(counterRefForDoneBooking, {
              // This In Firestore
              done_6_ClassTow: 0,
            });
          console.log("resetSecoundClass_6");

          } catch (error) {
            console.error("Error resetSecoundClass_6 : ", error);
          }
      }
      
    };

    /* 
        End Backend
    */


    /* 
        Start For Language Translation
    */
    const [chooseLang, setChooseLang] = useState('En');

    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [isOpen, setIsOpen] = useState(false);

    const languages = {
        en: 'En',
        fr: 'Fr',
        ar: 'Ar',
      };

      const toggleDropdown = () => setIsOpen(!isOpen);

      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        setIsOpen(false);
      };
    
      const getAvailableLanguages = () => {
        return Object.keys(languages).filter((lang) => lang !== selectedLanguage);
      };

    const { t } = useTranslation();
    const { i18n } = useTranslation();

    /* 
        End For Language Translation
    */

    const navigate = useNavigate();

    const handleLogout = async()=> {
        try {
            await signOut(auth);
            navigate('/Login');
            localStorage.removeItem('user');

        } catch (error) {
            console.error(error);
            alert(error.message);
        }
    };
    

    return(
        <>
            <div className='my-admin'>
                {/* Start Navbar */}
                <div className='navbar'>
                    <div className='left'>
                        <i className="fa-solid fa-file-circle-check"></i>
                        <h1>{t('Prof Youcef')}</h1>
                    </div>

                    <div className='right'>
                        <div className='top-content'>
                            <h3>
                            {t('Manage all your appointments easily from one place')}
                            
                            </h3>

                            <div className='lang'>

                                        {/* Test Start */}
                                        <button onClick={()=> {
                                                toggleDropdown();
                                                //setIsLangShow(!isLangShow);
                                        }}>
                                                {languages[selectedLanguage]}
                                                <i className="fa-solid fa-sort-down"></i>
                                        </button>

                                            {isOpen && (
                                                <div className='my-lang' style={{height: 'auto', padding: '0.5em', transition: '0.3s'}}>
                                                    {getAvailableLanguages().map((lang) => (

                                                    <div className='character' key={lang} onClick={() => changeLanguage(lang)}>
                                                        {languages[lang]}
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                        {/* Test End */}

                            </div>
                            
                            <button className='LogOut' onClick={handleLogout}>{t('LogOut')}</button>

                        </div>
                    </div>
                </div>
                {/* End Navbar */}


                {/* Start Hero Section */}
                <div className='my-hero'>
                    <div className='hero'>
                        <div className='left'>
                            <h1>
                            {t('Wellcome Back,')}
                              <br />
                            {t('Prof Youcef')}
                            
                            </h1>
                            <Link to='/Confirmation'>
                                <button>{t('Manage Your Last Confirmation Booking')}</button>
                            </Link>
                            
                            <button onClick={()=> {deleteAllDocuments()}} className='cancel-all'>{t('Cancellation Of All Booking')}</button>

                            {/* {
                                myData.map((e)=> {
                                    console.log(e);
                                    return(
                                        <>
                                            <h2>{`This is ${e.lastName}`}</h2>
                                        </>
                                    )
                                })
                            } */}

                        </div>
                        <div className='right'>
                            <img src={manage_img} />
                        </div>
                    </div>
                </div>
                {/* End Hero Section */}



                {/* Start My Form */}
                <div className='my-booking-form'>
                    <div className="head">
                        {/*start first-class-schedule */}
                        <h2>
                            <span></span>
                            {t('Manage All First Class Schedule')}
                            <span></span>
                        </h2>

                        
                        <div className='class-schedule'>
                          <button onClick={()=> {resetFirstClass_1()}}>{t('Class-1')}</button>
                          <button onClick={()=> {resetFirstClass_2()}}>{t('Class-2')}</button>
                          <button onClick={()=> {resetFirstClass_3()}}>{t('Class-3')}</button>
                          <button onClick={()=> {resetFirstClass_4()}}>{t('Class-4')}</button>
                          <button onClick={()=> {resetFirstClass_5()}}>{t('Class-5')}</button>
                          <button onClick={()=> {resetFirstClass_6()}}>{t('Class-6')}</button>
                        </div>
                        {/*End first-class-schedule */}


                        {/*start secound-class-schedule */}
                        <h2>
                            <span></span>
                            {t('Manage All Secound Class Schedule')}
                            <span></span>
                        </h2>

                        <div className='class-schedule'>
                          <button onClick={()=> {resetSecoundClass_1()}}>{t('Class-1')}</button>
                          <button onClick={()=> {resetSecoundClass_2()}}>{t('Class-2')}</button>
                          <button onClick={()=> {resetSecoundClass_3()}}>{t('Class-3')}</button>
                          <button onClick={()=> {resetSecoundClass_4()}}>{t('Class-4')}</button>
                          <button onClick={()=> {resetSecoundClass_5()}}>{t('Class-5')}</button>
                          <button onClick={()=> {resetSecoundClass_6()}}>{t('Class-6')}</button>
                        </div>
                        {/*End secound-class-schedule */}
                    </div>

                    <div className="head">
                        <h2>
                            <span></span>
                            {t('Booking details for each student')}
                            <span></span>
                        </h2>
                    </div>

                    {
                        myData.map((e, index)=> {
                            //console.log(e.findIndex());
                            return(
                                <div className='details-form' key={e.id}>
                                    <h1>{`${index + 1}.`}</h1>

                                    <div className="form-container">
                                        <div className="form-row">
                                            <label className="form-label">{t('First Name:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.firstName}`} />
                                            <label className="form-label">{t('Phone Number:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.phoneNumber}`} />
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('First Class:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.firstClass}`} />
                                            <label className="form-label">{t('Second Class:')}</label>
                                            <input type="text" className="form-input" defaultValue={`${e.secoundClass}`} />
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('Date And Time Of Booking On Web:')}</label>
                                            {/* <input type="text" className="form-input" defaultValue={`${formatTimestamp(e.TimeStamp)}`} /> */}
                                            <input type="text" className="form-input" defaultValue={`${formatTimestamp(e.TimeStamp)}`} />

                                            <label className="form-label">{t('Specialization')}:</label>
                                            <input type="text" className="form-input" defaultValue={`${e.specialization}`} />
                                        </div>
                                    </div>

                                    <div className="form-buttons">
                                        <button onClick={(event)=> {
                                            console.log(e);

                                            handelConvermation(e.firstClass, e.secoundClass);
                                            handelConfirmationForArchive(e.firstName, e.lastName, e.phoneNumber, e.firstClass, e.secoundClass, e.TimeStamp, e.specialization);
                                            deleteUserAfterConfirm(e.id);

                                        }} className="form-button confirm">{t('Confirmation')}</button>

                                        <button onClick={()=> {

                                            // deleteUser(e.id) ? console.log('i will work after delete') : console.log('i will Not work')
                                           
                                            handelMinusOne(e.firstClass, e.secoundClass);
                                            deleteUser(e.id);

                                        }} className="form-button cancel">{t('Cancel')}</button>
                                    </div>
                                    
                                </div>
                            )
                        })
                    }

                    


                    {/* <div className='details-form'>
                        <h1>2.</h1>

                        <div className="form-container">
                            <div className="form-row">
                                <label className="form-label">{t('First Name:')}</label>
                                <input type="text" className="form-input" defaultValue="osama" />
                                <label className="form-label">{t('Phone Number:')}</label>
                                <input type="text" className="form-input" defaultValue="0011223366" />
                            </div>

                            <div className="form-row">
                                <label className="form-label">{t('First Class:')}</label>
                                <input type="text" className="form-input" defaultValue="Tuesday at 4 PM to 6 PM" />
                                <label className="form-label">{t('Second Class:')}</label>
                                <input type="text" className="form-input" defaultValue="Tuesday at 6 PM to 8 PM" />
                            </div>

                            <div className="form-row">
                                <label className="form-label">{t('Date And Time Of Booking On Web:')}</label>
                                <input type="text" className="form-input" defaultValue="1/8/2024 At 10:12 AM" />
                                <label className="form-label">{t('Specialization')}:</label>
                                <input type="text" className="form-input" defaultValue="M (Math)" />
                            </div>
                        </div>

                        <div className="form-buttons">
                            <button className="form-button confirm">{t('Confirmation')}</button>
                            <button className="form-button cancel">{t('Cancel')}</button>
                        </div>
                        
                    </div> */}

                    
                </div>
                {/* End My Form */}
                
            </div>

            {/* Start Footer */}
            <Footer />
            {/* End Footer */}
        </>
    );
};


export default Admin;