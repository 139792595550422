// import logo from './logo.svg';
// import './App.css';

import Admin from "./Components/Admin/Admin";
import Home from "./Components/Home/Home";
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from "./Components/Login/Login";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase/firebase";
import Confirmation from "./Components/Confirmation/Confirmation";

function App() {

  // to stay you login entel you refresh the page 
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      localStorage.setItem('user', JSON.stringify(currentUser));
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">

      {/* <div className="container">
          <h1>Site In Development</h1>
          <p>We are currently updating the site. Please check back later.</p>
      </div> */}

      <Routes>
        {/* To Enter in this component ==> must sign in */}
        <Route path="/Admin" element={user ? <Admin /> : <Navigate to="/Login" />} />
        <Route path="/Confirmation" element={user ? <Confirmation /> : <Navigate to="/Login" />} />

        {/* Normal Component */}
        <Route path="/" element={<Home />}></Route>
        <Route path="/Login" element={<Login />}></Route>
      </Routes>

      {/* <Home /> */}
      
      
    </div>
  );
}

export default App;
