import { signInWithEmailAndPassword } from 'firebase/auth';
import './style_login.css';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../../Firebase/firebase';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handelsub = async(e)=> {
        e.preventDefault();

        try {
            await signInWithEmailAndPassword(auth, email, password);
            //alert('Logged in successfully');

            navigate('/Admin');

        } catch (error) {
            console.error(error);
            alert(error.message);
          }
    };

    return(
        <>
            <div className="my-login">
                <h1>Welcome To Admin Panel Please Login</h1>

                <form onSubmit={handelsub}>
                    <div className='my-input'>
                        <div className="email">
                            <label>Enter Your Email</label>
                            <input type="text" name='email' value={email} onChange={(e)=> setEmail(e.target.value)}/>
                        </div>

                        <div className="pass">
                            <label>Enter Your Password</label>
                            <input type="password" name='password' value={password} onChange={(e)=> {setPassword(e.target.value)}}/>
                        </div>
                    </div>

                    <button type="submit">Login</button>
                </form>

                {/* <div className="to-sign-up">
                    <p>Not Have Acount ?</p>
                    <Link to='/SignUp'>SignUp Now</Link>
                </div> */}
            </div>  
        </>
    );
};


export default Login;