// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "welcome": "Welcome",

          '"Thank you for your booking. We will contact you within the next few hours to confirm your reservation."': 
          '"Thank you for your booking. We will contact you within the next few hours to confirm your reservation."',


          "Prof Youcef": "Mr Youcef",
          "Begin your path to success! Get 8 exclusive lessons each month for only 6000 DZD.": 
          "Begin your path to success! Get 8 exclusive lessons each month for only 6000 DZD.",
          "Act now": "Act now",

          "A Specialized Platform For": "A Specialized Platform For",
          "Online Booking.": "Online Booking.",
          "DZD / Month": "DZD / Month",
          "For 8 Classes": "For 8 Classes",
        

          // ==============================================
          // Start My Form
        "Book class dates that suit you": "Book class dates that suit you",
        "book now!": "book now!",
        "First Name:": "First Name:",
        "Secound Name:": "Secound Name:",
        "Phone Number:": "Phone Number:",

        "Specialization": "Specialization",
        "SE (Sciences Experimental)": "SE (Sciences Experimental)",
        "MT (Math Techniques)": "MT (Math Techniques)",
        "M (Math)": "M (Math)",



        "Book the time that suits you for the first and second class": "Book the time that suits you for the first and second class",
        
        
        "First Class Schedule": "First Class Schedule",
        "Tuesday": "Tuesday",
        "From": "From",
        "Pm": "Pm",
        "To": "To",
        "Am": "Am",

        "Number of seats available": "Number of seats available",
        "Number of seats still under reservation": "Number of seats still under reservation",
        "Number of reserved seats": "Number of reserved seats",
        "Select": "Select",

        "Class Is Full Cmompleted": "Class Is Full Cmompleted",

        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",

        "Secound Class Schedule": "Secound Class Schedule",

        "Our location is easy to find": "Our location is easy to find",

        "Avenue d'Arcole, Gambetta, Oran - Algeria": "Avenue d'Arcole, Gambetta, Oran - Algeria",

          // End My Form
          // ==============================================



          // ==============================================
          // Start Admin
          "Manage all your appointments easily from one place": 
          "Manage all your appointments easily from one place",


          "LogOut": "LogOut",


          "Wellcome Back,": "Wellcome Back,",
          "Manage Your Last Confirmation Booking": "Manage Your Last Confirmation Booking",
          "Cancellation Of All Booking": "Cancellation Of All Booking",

          
          "Manage All First Class Schedule": "Manage All First Class Schedule",
          "Manage All Secound Class Schedule": "Manage All Secound Class Schedule",
          "Class-1": "Class-1",
          "Class-2": "Class-2",
          "Class-3": "Class-3",
          "Class-4": "Class-4",
          "Class-5": "Class-5",
          "Class-6": "Class-6",


          "Booking details for each student": "Booking details for each student",
          "First Class:": "First Class:",
          "Second Class:": "Second Class:",
          "Date And Time Of Booking On Web:": "Date And Time Of Booking On Web:",


          "Confirmation": "Confirmation",
          "Cancel": "Cancel",

          // End Admin
          // ==============================================




          // ==============================================
          // Start Confirmation
          "Manage all your cofirmation from one place": "Manage all your cofirmation from one place",
          "Delete All": "Delete All",



          // End Confirmation
          // ==============================================
          
          
          // Add more translations
        }
      },
      ar: {
        translation: {
          "welcome": "مرحباً",

           '"Thank you for your booking. We will contact you within the next few hours to confirm your reservation."': 
          '"شكرًا لك. سنتواصل معك خلال الساعات القليلة القادمة لتأكيد حجزك."',


          "Prof Youcef": "الاستاذ يوسف",
          "Begin your path to success! Get 8 exclusive lessons each month for only 6000 DZD.": 
          "ابدأ طريقك نحو النجاح! احصل على 8 دروس حصرية كل شهر فقط مقابل 6000 د.ج.",
          "Act now": "!احجز الان",


          "LogOut": "تسجيل الخروج",


          "A Specialized Platform For": "منصة متخصصة في",
          "Online Booking.": "الحجز عبر الإنترنت",
          "6000 DZD For 8 Classes": "6000 دينار جزائري مقابل 8 دروس",
          "Book Now": "احجز الآن",
          "DZD / Month": "دج/الشهر",
          "For 8 Classes": "مقابل 8 حصص",
          // ==============================================
          // Start My Form
        "Book class dates that suit you": "احجز مواعيد الدروس التي تناسبك",
        "First Name:": "الاسم الأول:",
        "Secound Name:": "الاسم الثاني:",
        "Phone Number:": "رقم الهاتف:",

        "Specialization": "التخصص",
        "SE (Sciences Experimental)": "العلوم التجريبية (SE)",
        "MT (Math Techniques)": "تقنيات الرياضيات (MT)",
        "M (Math)": "الرياضيات (M)",



        "Manage All First Class Schedule": "قم بإدارة جميع مواعيد الحصص الاولي",
        "Manage All Secound Class Schedule": "قم بإدارة جميع مواعيد الحصص الثانية",
          "Class-1": "الحصه الاولي",
          "Class-2": "الحصه الثانيه",
          "Class-3": "الحصه الثالثه",
          "Class-4": "الحصه الرابعه",
          "Class-5": "الحصه الخامسه",
          "Class-6": "الحصه السادسه",



        "Book the time that suits you for the first and second class": "احجز الوقت الذي يناسبك للحصص الأولى والثانية",
        
        
        "First Class Schedule": "مواعيد الحصة الأولى",
        "Tuesday": "الثلاثاء",
        "From": "من",
        "Pm": "مساءً",
        "To": "الي",
        "Am": "صباحاً",

        "Number of seats available": "عدد المقاعد المتاحة",
        "Number of seats still under reservation": "عدد المقاعد قيد الحجز",
        "Number of reserved seats": "عدد المقاعد المحجوزة",
        "Select": "اختار",

        "Class Is Full Cmompleted": "تم حجز المقاعد بالكامل",

        "Thursday": "الخميس",
        "Friday": "الجمعه",
        "Saturday": "السبت",

        "Secound Class Schedule": "مواعيد الحصة الثانية",

        "Our location is easy to find": "عنواننا سهل الوصول له",

        "Avenue d'Arcole, Gambetta, Oran - Algeria": "شارع أركول، غامبيتا، وهران - الجزائر",

        

        // End My Form
        // ==============================================



        // ==============================================
          // Start Admin
          "Manage all your appointments easily from one place": 
          "قم بإدارة جميع مواعيدك بسهولة من مكان واحد",


          "Wellcome Back,": "،مرحباً بعودتك",
          "Reservation confirmation for all students": "تأكيد الحجز لجميع الطلاب",

          "Manage Your Last Confirmation Booking": "قم بإدارة جميع الحجوزرات السابقة",
          "Cancellation Of All Booking": "إلغاء جميع الحجوزرات",


          "Booking details for each student": "تفاصيل الحجز لكل طالب",
          "First Class:": "الحصه الاولي:",
          "Second Class:": "الحصه الثانيه:",
          "Date And Time Of Booking On Web:": "تاريخ ووقت الحجز عبر الموقع:",


          "Confirmation": "تأكيد",
          "Cancel": "إلغاء",


          // End Admin
          // ==============================================



          // ==============================================
          // Start Confirmation
          "Manage all your cofirmation from one place": "قم بإدارة جميع الحجوزات من مكان واحد",
          "Delete All": "امسح الكل",



          // End Confirmation
          // ==============================================

          // Add more translations
        }
      },
      fr: {
        translation: {
          "welcome": "Bienvenue",


          '"Thank you for your booking. We will contact you within the next few hours to confirm your reservation."': 
          '"Merci pour votre réservation. Nous vous contacterons dans les prochaines heures pour confirmer votre réservation."',


          "Prof Youcef": "Prof Youcef",
          "Begin your path to success! Get 8 exclusive lessons each month for only 6000 DZD.": 
          "Commencez votre chemin vers le succès ! Recevez 8 leçons exclusives chaque mois pour seulement 6000 DZD",
          "Act now": "Réservez maintenant!",


          "LogOut": "Se déconnecter",


          "A Specialized Platform For": "Une plateforme spécialisée pour",
          "Online Booking.": "Réservation en ligne",
          "DZD / Month": "DZD / Mois",
          "For 8 Classes": "Pour 8 cours",
          "Book Now": "Réservez maintenant",


          // ==============================================
          // Start My Form
        "Book class dates that suit you": "Réservez les dates des cours qui vous conviennent",
        "First Name:": "Prénom :",
        "Secound Name:": "Deuxième prénom :",
        "Phone Number:": "Numéro de téléphone :",

        "Specialization": "Spécialisation",
        "SE (Sciences Experimental)": "SE (Sciences Expérimentales)",
        "MT (Math Techniques)": "MT (Techniques Mathématiques)",
        "M (Math)": "M (Mathématiques)",


        "Manage All First Class Schedule": "Gérer tous les horaires de la première classe",
        "Manage All Secound Class Schedule": "Gérer tous les horaires de la deuxième classe",
          "Class-1": "Classe-1",
          "Class-2": "Classe-2",
          "Class-3": "Classe-3",
          "Class-4": "Classe-4",
          "Class-5": "Classe-5",
          "Class-6": "Classe-6",


        "Book the time that suits you for the first and second class": "Réservez le créneau qui vous convient pour le premier et le deuxième cours",
        
        
        "First Class Schedule": "Emploi du temps du premier cours",
        "Tuesday": "Mardi",
        "From": "De",
        "Pm": "Pm",
        "To": "À",
        "Am": "Am",

        "Number of seats available": "Nombre de places disponibles",
        "Number of seats still under reservation": "Nombre de places encore en réservation",
        "Number of reserved seats": "Nombre de places réservées",
        "Select": "Sélectionner",

        "Class Is Full Cmompleted": "La classe est complète",

        "Thursday": "Jeudi",
        "Friday": "Vendredi",
        "Saturday": "Samedi",

        "Secound Class Schedule": "Emploi du temps du deuxième cours",

        "Our location is easy to find": "Notre emplacement est facile à trouver",

        "Avenue d'Arcole, Gambetta, Oran - Algeria": "Avenue d'Arcole, Gambetta Oran -Algérie",

        // End My Form
        // ==============================================



        // ==============================================
          // Start Admin
          "Manage all your appointments easily from one place": 
          "Gérez tous vos rendez-vous facilement depuis un seul endroit",


          "Wellcome Back,": "Bienvenue à nouveau,",
          "Manage Your Last Confirmation Booking": "Gérez votre dernière réservation confirmée",
          "Cancellation Of All Booking": "Annulation de toutes les réservations",


          "Booking details for each student": "Détails de réservation pour chaque étudiant",
          "First Class:": "Premier cours:",
          "Second Class:": "Deuxième cours:",
          "Date And Time Of Booking On Web:": "Date et heure de la réservation sur le web:",


          "Confirmation": "Confirmation",
          "Cancel": "Annuler",

          // End Admin
          // ==============================================



          // ==============================================
          // Start Confirmation
          "Manage all your cofirmation from one place": "Gérez toutes vos confirmations depuis un seul endroit",
          "Delete All": "Tout supprimer",



          // End Confirmation
          // ==============================================


          // Add more translations
        }
      }
    },
    lng: "en", // default language
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
