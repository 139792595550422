import React, { useEffect, useRef, useState } from 'react';
import './style_myForm.css';
import location_img from '../images/ts-map-pin.png';
import success_img from '../images/success.png';
import { useTranslation } from 'react-i18next';
import { addDoc, collection, doc, getDocs, increment, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
import { Link } from 'react-router-dom';

const MyForm = React.forwardRef((props, ref)=> {
    // state to create form on database
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [specialization, setSpecialization] = useState('SE (Sciences Experimental)');

    // State For First Class
    const [firstClass, setFirstClass] = useState('');
    const [secoundClass, setsecoundClass] = useState('');

    //console.log(firstName, lastName, phoneNumber, specialization, firstClass, secoundClass);



    const [isChecked, setIsChecked] = useState(null);
    const [isSecoundChecked, setIsSecoundChecked] = useState(null);



    //const [isallowed, setIsallowed] = useState(true);

    // Massages
    const [successMassage, setSeccessMassage] = useState(false);



    const handelSelect = (index)=> {
        setIsChecked(index);
    };

    const handelSecoundSelect = (index)=> {
        setIsSecoundChecked(index);
    };


    /* 
        Start Backend
    */

        // To Read All Data
        const [myData, setMyData] = useState([]);
        console.log(myData);


        useEffect(()=> {

            const getMyData = async()=> {
                const data = await getDocs(collection(db, 'counters'));
    
                const allData = data.docs.map((e)=> ({...e.data(), id: e.id}));
    
                setMyData(allData);
            };
    
            getMyData();
    
        }, []);

    

    const UsersCollectionRef = collection(db, 'items');
    const counterRef = doc(db, "counters", "AmPTnb6yWna8Ln3gyeDS");  // AmPTnb6yWna8Ln3gyeDS ==> id of document قيد الحجز
    const counterRefForAvaliableBooking = doc(db, "counters", "counterForAvaliableBooking");  // counterForAvaliableBooking ==> id of document


    // To Create Data On Database
    const handelSubmit = async(e)=> {
        e.preventDefault();
        console.log('Ok');
        setSeccessMassage(true);

        await addDoc(UsersCollectionRef, {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            specialization: specialization,
            firstClass: firstClass,
            secoundClass: secoundClass,
            TimeStamp: serverTimestamp(),
        });


        // =================== To Update First Class  ===========================//
        if(firstClass === 'Tuesday - From 2 Pm To 4 Pm') {
            console.log('First-1');

            const incrementFirstClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    // This In Firestore
                    firstClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fristAvaliableClassOne: increment(-1),
                  });
                  console.log("firstClassOne incremented");

                } catch (error) {
                  console.error("Error incrementing firstClassOne: ", error);
                }
              };
              incrementFirstClassOne();

            

        } else if(firstClass === 'Tuesday- From 4 Pm To 6 Pm') {
            console.log('secound-2');

            const incrementSecondClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    secoundClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    secoundAvaliableClassOne: increment(-1),
                  });
                  
                  console.log("secoundClassOne incremented");
                } catch (error) {
                  console.error("Error incrementing secoundClassOne: ", error);
                }
            };
            incrementSecondClassOne();
            

        } else if(firstClass === 'Tuesday- From 6 Pm To 8 Pm') {
            console.log('Class-3');

            const incrementThirdClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    thirdClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    thirdAvaliableClassOne: increment(-1),
                  });
                  
                  console.log("thirdClassOne incremented");
                } catch (error) {
                  console.error("Error incrementing thirdClassOne: ", error);
                }
            };
            incrementThirdClassOne();

            
        } else if(firstClass === 'Thursday- From 6 Pm To 8 Pm') {
            console.log('Class-4');

            const incrementfourthClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    fourthClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fourthAvaliableClassOne: increment(-1),
                  });
                  
                  console.log("fourthClassOne incremented");
                } catch (error) {
                  console.error("Error incrementing fourthClassOne: ", error);
                }
            };
            incrementfourthClassOne();
            

        } else if(firstClass === 'Friday- From 8 Am To 10 Am') {
            console.log('Class-5');

            const incrementfifthClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    fifthClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fifthAvaliableClassOne: increment(-1),
                  });
                  
                  console.log("fifthClassOne incremented");
                } catch (error) {
                  console.error("Error incrementing fifthClassOne: ", error);
                }
            };
            incrementfifthClassOne();

            
        } else if(firstClass === 'Friday- From 10 Am To 12 Pm') {
            console.log('Class-6');

            const incrementsixthClassOne = async () => {
                try {
                  await updateDoc(counterRef, {
                    sixthClassOne: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    sixthAvaliableClassOne: increment(-1),
                  });
                  
                  console.log("sixthClassOne incremented");
                } catch (error) {
                  console.error("Error incrementing sixthClassOne: ", error);
                }
            };
            incrementsixthClassOne();
            
        } else {
            console.log('No Time Match in First Schedule');
        }


        // =================== To Update Secound Class  ===========================//
        if(secoundClass === 'Friday - From 2 Pm To 4 Pm') {
            console.log('Secound-1');

            const incrementFirstClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    firstClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fristAvaliableClassTow: increment(-1),
                  });
                  console.log("firstClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing firstClassTow: ", error);
                }
              };
              incrementFirstClassTow();



        } else if(secoundClass === 'Friday - From 4 Pm To 6 Pm') {
            console.log('Secound-2');

            const incrementSecoundClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    secoundClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    secoundAvaliableClassTow: increment(-1),
                  });
                  console.log("secoundClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing secoundClassTow: ", error);
                }
              };
              incrementSecoundClassTow();



        } else if(secoundClass === 'Saturday - From 8 Am To 10 Am') {
            console.log('Secound-3');

            const incrementThirdClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    thirdClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    thirdAvaliableClassTow: increment(-1),
                  });

                  console.log("thirdClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing thirdClassTow: ", error);
                }
              };
              incrementThirdClassTow();

        }



        else if(secoundClass === 'Saturday - From 10 Am To 12 Pm') {
            console.log('Secound-4');

            const incrementFourthClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    fourthClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fourthAvaliableClassTow: increment(-1),
                  });

                  console.log("fourthClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing fourthClassTow: ", error);
                }
              };
              incrementFourthClassTow();

        }




        else if(secoundClass === 'Saturday - From 2 Pm To 4 Pm') {
            console.log('Secound-5');

            const incrementFifthClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    fifthClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    fifthAvaliableClassTow: increment(-1),
                  });

                  console.log("fifthClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing fifthClassTow: ", error);
                }
              };
              incrementFifthClassTow();

        }



        else if(secoundClass === 'Saturday - From 4 Pm To 6 Pm') {
            console.log('Secound-6');

            const incrementSixthClassTow = async () => {
                try {
                  await updateDoc(counterRef, {
                    sixthClassTow: increment(1),
                  });

                  await updateDoc(counterRefForAvaliableBooking, {
                    // This In Firestore
                    sixthAvaliableClassTow: increment(-1),
                  });

                  console.log("sixthClassTow incremented");

                } catch (error) {
                  console.error("Error incrementing sixthClassTow: ", error);
                }
              };
              incrementSixthClassTow();

        } else {
            console.log('No Time Match in Secound Schedule');
        }
    };




    /* 
        End Backend
    */


    // To Make Translate
    const { t } = useTranslation();

    

    return(
        <>
            {/* Start Massages */}
            <div className='success-message' style={successMassage === false ? {display: 'none'} : {display: "flex"}}>
                    
                    <div className='my-massage'>
                        
                        <img src={success_img} />
                        
                        <h3>
                        <i className="fa-solid fa-circle-check"></i>
                        <br />
                        {t('"Thank you for your booking. We will contact you within the next few hours to confirm your reservation."')}
                        
                        </h3>
                    </div>
                    <i onClick={()=> {
                        setSeccessMassage(false);
                        window.location.reload();
                        
                    }} className="fa-solid fa-circle-xmark"></i>
                
            </div>
            {/* End Massages */}

            <div className="my-form">
                <div className="head" ref={ref}>
                    <h2>
                        <span></span>
                        {t('Book class dates that suit you')}
                        
                        <span></span>
                    </h2>
                </div>

                <div className='form'>
                    <form onSubmit={handelSubmit}>
                        <div className='top-form'>

                                {/* Test */}
                                <div className="form-container">
                                        <div className="form-row">
                                            <label className="form-label">{t('First Name:')}</label>
                                            <input type="text" className="form-input"  onChange={(e)=> {setFirstName(e.target.value)}} placeholder='Ex: Kareem' required/>
                                            
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('Secound Name:')}</label>
                                            <input type="text" className="form-input" onChange={(e)=> {setLastName(e.target.value)}} placeholder='Ex: Sayed' required />
                                            
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('Phone Number:')}</label>
                                            <input type="number" className="form-input" onChange={(e)=> {setPhoneNumber(e.target.value)}} placeholder='Ex: 0123456789' required />
                                            
                                        </div>

                                        <div className="form-row">
                                            <label className="form-label">{t('Specialization')}</label>
                                            <select onChange={(e)=> {setSpecialization(e.target.value)}} required>
                                                <option value="SE (Sciences Experimental)">{t('SE (Sciences Experimental)')}</option>
                                                <option value="MT (Math Techniques)">{t('MT (Math Techniques)')}</option>
                                                <option value="M (Math)">{t('M (Math)')}</option>
                                            </select>
                                            
                                        </div>
                                </div>

                                <div className="head">
                                    <h2>
                                        <span></span>
                                        {t('Book the time that suits you for the first and second class')}
                                        
                                        <span></span>
                                    </h2>
                                </div>
                        </div>


                        <div className='Schedule'>
                            {/* Start Left Schedule*/}
                            <div className='left'>
                                
                                <div className='top'>
                                    <i className="fa-regular fa-calendar-check"></i>
                                    <h1>{t('First Class Schedule')}</h1>   
                                </div>

                                <div className='card' style={isChecked === 1 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Tuesday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 2 {t('Pm')}  {t('To')} 4 {t('Pm')} </p>
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fristAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.firstClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneFirstClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            console.log(e.firstClassOne);
                                            //return handelselecttion(e.fristAvaliableClassOne)
                                            return(
                                                
                                                <div key={e.id} className='if-it-complete'>
                                                    {/* {(e.firstClassOne != 0 && e.doneFirstClassOne != 30) ? ( */}
                                                        
                                                    {(e.fristAvaliableClassOne > 0) ? (
                                                        <div className='select'>
                                                            
                                                            <label htmlFor="1">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSelect(1);
                                                                setFirstClass('Tuesday - From 2 Pm To 4 Pm');
                                                            }} type="radio" id='1' name="options" value="Tuesday - From 2 Pm To 4 Pm" required/>
                                                        </div>

                                                    ) : (
                                                        
                                                        null
                                                        // <div className='select'>
                                                            
                                                        //     <label htmlFor="1">{t('Select')}</label>
                                                        //     <input onClick={()=> {
                                                        //         handelSelect(1);
                                                        //         setFirstClass('Tuesday - From 2 Pm To 4 Pm');
                                                        //     }} type="radio" id='1' name="options" value="Tuesday - From 2 Pm To 4 Pm" required/>
                                                        // </div>
                                                        // <div className='completed'>
                                                            
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isChecked === 2 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Tuesday')}</h3>
                                    </div>

                                    <div className='line'>
                                    <p>{t('From')} 4 {t('Pm')}  {t('To')} 6 {t('Pm')} </p>
                                        {/* <p>From 4 Pm To 6 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.secoundAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.secoundClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneSecoundClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.secoundAvaliableClassOne > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="2">{t('Select')}</label>
                                                            
                                                            <input onClick={()=> {
                                                                handelSelect(2);
                                                                setFirstClass('Tuesday- From 4 Pm To 6 Pm');
                                                            }} type="radio" id='2' name="options" value="Tuesday- From 4 Pm To 6 Pm" required/>

                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isChecked === 3 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Tuesday')}</h3>
                                    </div>

                                    <div className='line'>
                                    <p>{t('From')} 6 {t('Pm')}  {t('To')} 8 {t('Pm')} </p>
                                        {/* <p>From 6 Pm To 8 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} :  {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.thirdAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.thirdClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneThirdClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.thirdAvaliableClassOne > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="3">{t('Select')}</label>
                                                            
                                                            <input onClick={()=> {
                                                                handelSelect(3);
                                                                setFirstClass('Tuesday- From 6 Pm To 8 Pm');
                                                            }} type="radio" id='3' name="options"  required/>

                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isChecked === 4 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Thursday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 6 {t('Pm')}  {t('To')} 8 {t('Pm')} </p>
                                        {/* <p>From 6 Pm To 8 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fourthAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fourthClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_4_ClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.fourthAvaliableClassOne > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="4">{t('Select')}</label>
                                                            
                                                            <input onClick={()=> {
                                                                handelSelect(4);
                                                                setFirstClass('Thursday- From 6 Pm To 8 Pm');
                                                            }} type="radio" id='4' name="options"  required/>

                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isChecked === 5 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Friday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 8 {t('Am')}  {t('To')} 10 {t('Am')} </p>
                                        {/* <p>From 8 Am To 10 Am</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fifthAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fifthClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_5_ClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.fifthAvaliableClassOne > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="5">{t('Select')}</label>
                                                            
                                                            <input onClick={()=> {
                                                                handelSelect(5);
                                                                setFirstClass('Friday- From 8 Am To 10 Am');
                                                            }} type="radio" id='5' name="options"  required/>

                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isChecked === 6 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Friday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 10 {t('Am')}  {t('To')} 12 {t('Pm')} </p>
                                        {/* <p>From 10 Am To 12 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.sixthAvaliableClassOne}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.sixthClassOne}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_6_ClassOne}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            console.log(e);
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.sixthAvaliableClassOne > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="6">{t('Select')}</label>
                                                            
                                                            <input onClick={()=> {
                                                                handelSelect(6);
                                                                setFirstClass('Friday- From 10 Am To 12 Pm');
                                                            }} type="radio" id='6' name="options"  required/>

                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                            </div>
                            {/* End Left Schedule*/}


                            <div className='vertical-line'></div>


                            {/* Start right Schedule*/}
                            <div className='right'>
                                <div className='top'>
                                    <i className="fa-regular fa-calendar-check"></i>
                                    <h1>{t('Secound Class Schedule')}</h1>   
                                </div>

                                <div className='card' style={isSecoundChecked === 1 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Friday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 2 {t('Pm')}  {t('To')} 4 {t('Pm')} </p>
                                        {/* <p>From 2 Pm To 4 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fristAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.firstClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneFirstClassTow}</span>
                                            )
                                        })}</p>
                                    </div>

                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.fristAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-1">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(1);
                                                                setsecoundClass('Friday - From 2 Pm To 4 Pm')
                                                            }} type="radio" id='Secound-1' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }

                                    {/* <div className='select'>
                                        <label htmlFor="Secound-1">{t('Select')}</label>
                                        <input onClick={()=> {
                                            handelSecoundSelect(1);
                                            setsecoundClass('Friday - From 2 Pm To 4 Pm')
                                        }} type="radio" id='Secound-1' name="secound" required/>
                                    </div> */}
                                </div>

                                <div className='card' style={isSecoundChecked === 2 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Friday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 4 {t('Pm')}  {t('To')} 6 {t('Pm')} </p>
                                        {/* <p>From 4 Pm To 6 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.secoundAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.secoundClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneSecoundClassTow}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.secoundAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-2">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(2);
                                                                setsecoundClass('Friday - From 4 Pm To 6 Pm')
                                                            }} type="radio" id='Secound-2' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isSecoundChecked === 3 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Saturday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 8 {t('Am')}  {t('To')} 10 {t('Am')} </p>
                                        {/* <p>From 8 Am To 10 Am</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.thirdAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.thirdClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.doneThirdClassTow}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.thirdAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-3">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(3);
                                                                setsecoundClass('Saturday - From 8 Am To 10 Am')
                                                            }} type="radio" id='Secound-3' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isSecoundChecked === 4 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Saturday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 10 {t('Am')}  {t('To')} 12 {t('Pm')} </p>
                                        {/* <p>From 10 Am To 12 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fourthAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fourthClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_4_ClassTow}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.fourthAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-4">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(4);
                                                                setsecoundClass('Saturday - From 10 Am To 12 Pm')
                                                            }} type="radio" id='Secound-4' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isSecoundChecked === 5 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Saturday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 2 {t('Pm')}  {t('To')} 4 {t('Pm')} </p>
                                        {/* <p>From 2 Pm To 4 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fifthAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.fifthClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_5_ClassTow}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.fifthAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-5">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(5);
                                                                setsecoundClass('Saturday - From 2 Pm To 4 Pm')
                                                            }} type="radio" id='Secound-5' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                                <div className='card' style={isSecoundChecked === 6 ? {border: '4px solid green'} : {border: '1px solid rgba(172, 172, 172, 0.692)'}}>
                                    <div className='head-of-card'>
                                        <i className="fa-regular fa-calendar-check"></i>
                                        <h3>{t('Saturday')}</h3>
                                    </div>

                                    <div className='line'>
                                        <p>{t('From')} 4 {t('Pm')}  {t('To')} 6 {t('Pm')} </p>
                                        {/* <p>From 4 Pm To 6 Pm</p> */}
                                        <hr />
                                    </div>

                                    <div className='content'>
                                        <p>- {t('Number of seats available')} : {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.sixthAvaliableClassTow}</span>
                                            )
                                        })} </p>

                                        <p>- {t('Number of seats still under reservation')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.sixthClassTow}</span>
                                            )
                                        })}</p>
                                        <p>- {t('Number of reserved seats')}: {myData.map((e)=>{
                                            return(
                                                <span key={e.id}>{e.done_6_ClassTow}</span>
                                            )
                                        })}</p>
                                    </div>


                                    {
                                        myData.map((e)=> {
                                            return(
                                                <div key={e.id} className='if-it-complete'>
                                                    {e.sixthAvaliableClassTow > 0 ? (
                                                        
                                                        <div className='select'>
                                                            <label htmlFor="Secound-6">{t('Select')}</label>
                                                            <input onClick={()=> {
                                                                handelSecoundSelect(6);
                                                                setsecoundClass('Saturday - From 4 Pm To 6 Pm')
                                                            }} type="radio" id='Secound-6' name="secound" required/>
                                                        </div>

                                                    ) : (
                                                        null
                                                        // <div className='completed'>
                                                        //     <br />
                                                        //     <h4>{t('Class Is Full Cmompleted')}</h4>
                                                        // </div>
                                                        
                                                    )}
                                               </div>
                                            )
                                        })
                                    }


                                    
                                </div>

                            </div>
                            {/* End right Schedule*/}
                        </div>

                        <button className='book' type="submit">{t('Book Now')}</button>
                    </form>

                    
                </div>

                <div className="head">
                    
                        <h2>
                            {/* Our location */}
                            <span></span>
                            {t('Our location is easy to find')}
                            <span></span>
                            {/* Our location */}
                        </h2>
                    
                </div>

                {/* Start Map */}
                <div className='map' style={{ width: '100%', height: '500px' }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d51843.99304355983!2d-0.6610271563273786!3d35.695475788398554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenue%20d&#39;Arcole%2C%20Gambetta%20Oran%20-Alg%C3%A9rie!5e0!3m2!1sen!2seg!4v1723364604735!5m2!1sen!2seg"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>

                
                {/* End Map */}

                <div className='address'>
                    <img src={location_img}/>
                    <h2>{t("Avenue d'Arcole, Gambetta, Oran - Algeria")}</h2>
                </div>
            </div>
        </>
    );
});


export default MyForm;