// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBD8WYKcncQQR3anPRoMG1ku23JCWFg34",
  authDomain: "education-website-backend.firebaseapp.com",
  projectId: "education-website-backend",
  storageBucket: "education-website-backend.appspot.com",
  messagingSenderId: "539032620107",
  appId: "1:539032620107:web:7a707ba5d2b9e4f90091ce"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);