import './style_footer.css';

function Footer() {
    return(
        <>
            <div className='my-footer'>

                <div className='my-links'>

                    <a href='https://www.facebook.com/people/Prof-Youcef-%D8%A7%D9%84%D8%A3%D8%B3%D8%AA%D8%A7%D8%B0-%D9%8A%D9%88%D8%B3%D9%81/61563757063691/?mibextid=ZbWKwL'>
                        <i className="fa-brands fa-facebook-f"></i>
                    </a>

                    <a href='https://www.instagram.com/prof.youcef2/?igsh=MTl1aHl2cmJmNmN6dA%3D%3D'>
                        <i className="fa-brands fa-instagram"></i>
                    </a>

                    <a href='https://www.tiktok.com/@prof.youcef2?_t=8olI6CNTsF4&amp;_r=1'>
                        <i className="fa-brands fa-tiktok"></i>
                    </a>
                    
                </div>
            </div>
        </>
    );
};


export default Footer;